@import '../../../base/assets/scss/_base/config';

.games-categories {
  position: relative;

  .m-nc__carousel:not(.glide--slider){
    ul {
      .tab {
        flex: 1 0 11%;
      }
    }
  }
  .glide--slider {
    overflow: hidden;
  }

  .tabs {

    ul {
      padding-left: 0px;
      height: 52px;
      display: flex;
      width: 104%!important;

      .tab, .tabLink {
        &.active {
          background: $solitude;
          border: 0px solid $owl;
          color: $picasso;
        }
      }
      .tab {
        color: $backwater;
        cursor: pointer;
        display: inline-block;
        font-family: var(--font-family-heading);
        font-size: 16px;
        text-transform: capitalize;
        border-radius: 48px;
        border: 1px solid $owl;
        background: #FEFFFE;
        height: 52px;
        padding: 16px 32px 16px 32px;
        @media screen and (min-width: map-get($breakpoints, s)) and (max-width: (map-get($breakpoints, xxl))) {
          &-6::after {
            content: '';
            background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) -6.11%, #FFF 100%);
            width: 90px;
            flex-shrink: 0;
            position: absolute;
            height: 52px;
            top: 0;
            right: 16px;
          }
        }
      }
      .tabLink {
        cursor: pointer;
        display: flex;
        font-family: var(--font-family-heading);
        text-transform: capitalize;
        margin: 0px;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        justify-content: center;
      }

      @media screen and (max-width: (map-get($breakpoints, s) - 1px)) {
        width: 100%!important;
        padding-left: 10px;
        display: flex;
        .tab {
          font-size: 12px;
          height: 40px;
          padding: 12px 20px 12px 20px;

        }
        .tabLink {
          font-size: 12px;
          font-weight: 600;
          line-height: 16px;
        }
      }
      @media screen and (max-width: (map-get($breakpoints, m) - 1px)) {
        width: 102%!important;
      }
      @media screen and (min-width: map-get($breakpoints, xl)) {
        width: 100%
      }
    }
  }
  .m-nc {
    &__controls {

      [disabled='true'] {
        display: none;
      }

      &--prev, &--next {
        position: absolute;
        z-index: 10;
        width: 36px;
        height: 36px;
        border: none;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.10)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.10));
        background-color: rgba(255, 255, 255, 1);
        border-radius: 100%;
        top: 0;



        .m-nc__controls--container {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 25px;
          width: 14px;

        }

        &:hover {
          color: $dory;
        }

        svg {
          fill: $gotham;
        }
      }
      &--next {
        right: 0.75rem;
        margin-top: 24px;
        margin-right: -13px;

        .m-nc__controls--container {
          border-top-left-radius: 48px;
          border-bottom-left-radius: 48px;
        }
      }

      &--prev {
        left: 0.75rem;
        padding-right: 0px;
        margin-top: 24px;
        margin-left: -13px;

        .m-nc__controls--container {
          border-top-right-radius: 48px;
          border-bottom-right-radius: 48px;
        }
      }
    }
  }

  .games-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .game-item {
      margin: 0.5%;
      width: 32.6%;

      div {
        overflow: hidden;
        position: relative;
        border-radius: 16px;
        margin: 4px;

        img {
          height: 100%;
          min-height: 1px;
          width: 100%;
        }

        a {
          background-color: $brand-games-category-background-hover;
          display: flex;
          height: 100%;
          left: 0;
          opacity: 0;
          position: absolute;
          text-decoration: none;
          top: 0;
          transition-delay: 0s;
          transition-duration: 0.2s;
          transition-property: all;
          transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          width: 100%;

          div {
            margin: auto 8px;
            text-align: center;
            h3 {
              color: $white;
              font-family: var(--font-family-heading);
              font-size: 16px;
              letter-spacing: 0.05em;
              line-height: 1.1em;
              max-height: 2.4em;
              overflow: hidden;
              text-transform: uppercase;
              font-weight: 700;
              text-align: center;
            }

            p {
              color: #A9B1B9;
              font-family: var(--font-family-base);
              font-size: 11px;
              margin-bottom: 5px;
              margin-top: 5px;
              text-align: center;
              line-height: 12px;
            }

            .game-item-cta {
              background-color: $brand-games;
              border-radius: 48px;
              color: $white;
              font-family: var(--font-family-heading);
              font-size: 13px;
              margin-top: -4px;
              margin-bottom: 16px;
              padding: 14px 24px;
              text-transform: capitalize;
              white-space: nowrap;
              font-weight: 600;
              line-height: 16px;
              height: 44px;
              justify-content: center;
              display: flex;
              align-items: center;
              &:hover {
                background-color: $brand-games-hover;
              }

            }

          }
        }

        a:hover {
          opacity: 1;
        }
      }
    }

    .game-item:nth-child(3n + 1) {
      margin: 0.5% 0.5% 0.5% 0;
    }

    .game-item:nth-child(3n + 3) {
      margin: 0.5% 0 0.5% 0.5%;
    }
    @media screen and (max-width: (map-get($breakpoints, s) - 1px)) {
      .game-item{
        width: 48.4%;
        div {
          border-radius: 12px!important;
          a {
            background-color: transparent!important;
            div {
              visibility: hidden;
            }
          }
        }
      }
      .game-item:nth-child(3n + 1) {
        margin: 0.5% 0.5% 0.5% 0.5%!important;
      }
    }
    @media screen and (max-width: (map-get($breakpoints, m) - 1px)) {
      .game-item {
        div {
          a {
            div {
              h3 {
                max-height: 2em;
                font-size: 16px;
                line-height: 1em;
                font-weight: 700;
              }
              .game-item-cta {

                font-size: 13px;
                height: 32px;
                margin-bottom: 8px;
                padding: 8px 12px;
                margin-right: 20px;
                margin-left: 20px;
              }
            }
          }
        }
      }
    }
    @media screen and (min-width: map-get($breakpoints, m)) {
      .game-item {
        div {
          a {
            div {
              .game-item-cta {
                font-size: 13px;
                line-height: 16px;
                font-weight: 600;
                padding: 14px 24px;
                height: 52px;
                margin-right: 40px;
                margin-left: 40px;
              }
            }
          }
        }
      }
    }
  }
}
.games-category-title{
  text-transform: capitalize;
  font-family: var(--font-family-base);
  font-size: 36px;
  font-weight: 800;
  color: $batcave;
  letter-spacing: -1px;
  text-align: left;
}
